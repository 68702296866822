$(document).ready(function() { // запускаем скрипт после загрузки всех элементов
	/* засунем сразу все элементы в переменные, чтобы скрипту не приходилось их каждый раз искать при кликах */
    var modal__overlay = $('#modal__overlay'); // подложка, должна быть одна на странице
    var open_modal = $('.modal__open'); // все ссылки, которые будут открывать окна
    var close = $('.modal__close, #modal__overlay'); // все, что закрывает модальное окно, т.е. крестик и оверлэй-подложка
    var modal = $('.modal'); // все скрытые модальные окна

    open_modal.click( function(event){ // ловим клик по ссылке с классом open_modal
        $('html').css('overflow', 'hidden'); // выключаем скролл*/
        event.preventDefault(); // вырубаем стандартное поведение
        var div = $(this).attr('href'); // возьмем строку с селектором у кликнутой ссылки
        modal__overlay.fadeIn(200, //показываем оверлэй
            function(){ // после окончания показывания оверлэя
                $(div) // берем строку с селектором и делаем из нее jquery объект
                    .css('display', 'block')
                    .animate({opacity: 1, top: '50%'}, 400); // плавно показываем
            });
    });

    close.click( function(){ // ловим клик по крестику или оверлэю
        $('html').css('overflow-y', 'scroll'); // включаем скролл*/
        modal // все модальные окна
            .animate({opacity: 0, top: '45%'}, 200, // плавно прячем
                function(){ // после этого
                    $(this).css('display', 'none');
                    modal__overlay.fadeOut(400); // прячем подложку
                }
            );
    });
});
